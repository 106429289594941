<template>
    <vx-card :title="'View Processed Stock Transfer Order'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>STO Data</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Picking List Data</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="this.pickingCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="this.selectedDestWarehouse" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Delivery Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(this.reqDeliveryDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>ETA</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(this.eta)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-table stripe style="width:100%" :data="pickingItem">
                    <thead>
                        <tr>
                            <th style="vertical-align: middle;" rowspan="2">SKU Code</th>
                            <th style="vertical-align: middle;" rowspan="2">SKU Name</th>
                            <th style="vertical-align: middle;" rowspan="2">HU</th>
                            <th style="vertical-align: middle;" rowspan="2">Quantity</th>
                            <th style="text-align: center;" colspan="3">Suggestion</th>
                            <th style="text-align: center;" colspan="3">Actual</th>
                        </tr>
                        <tr>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                            <th>Storage Area</th>
                            <th>Batch Number</th>
                            <th>Expired Date</th>
                        </tr>
                    </thead>
                    <template>
                        <template v-for="(t, indext) in pickingItem">
                            <template v-if="t.StockRelocationPickingSuggestions.length <= t.StockRelocationPickingItemStocks.length">
                                <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.StockRelocationPickingItemStocks">
                                    <vs-td>{{ indextr != 0 ? '' : tr.SkuCode }}</vs-td>
                                    <vs-td>{{ indextr != 0 ? '' : tr.ItemName }}</vs-td>
                                    <vs-td>{{ indextr >= t.StockRelocationPickingSuggestions.length ? '' : t.StockRelocationPickingSuggestions[indextr].Unit }}</vs-td>
                                    <vs-td>{{ indextr >= t.StockRelocationPickingSuggestions.length ? '' : t.StockRelocationPickingSuggestions[indextr].Amount }}</vs-td>
                                    <vs-td>{{ indextr >= t.StockRelocationPickingSuggestions.length ? '' : t.StockRelocationPickingSuggestions[indextr].StorageCode }}</vs-td>
                                    <vs-td>{{ indextr >= t.StockRelocationPickingSuggestions.length ? '' : t.StockRelocationPickingSuggestions[indextr].Batch }}</vs-td>
                                    <vs-td>{{ indextr >= t.StockRelocationPickingSuggestions.length ? '' : formatDate(t.StockRelocationPickingSuggestions[indextr].ExpiredDate) }}</vs-td>
                                    <vs-td>{{ tr.StorageCode }}</vs-td>
                                    <vs-td>{{ tr.Batch }}</vs-td>
                                    <vs-td>{{ formatDate(t.StockRelocationPickingSuggestions[indextr].ExpiredDate) }}</vs-td>
                                </vs-tr>
                            </template>
                            <template v-else>
                                <vs-tr :key="`${indext}-${indextr}`" v-for="(tr, indextr) in t.StockRelocationPickingSuggestions">
                                    <vs-td>{{ indextr != 0 ? '' : tr.SkuCode }}</vs-td>
                                    <vs-td>{{ indextr != 0 ? '' : tr.ItemName }}</vs-td>
                                    <vs-td>{{ tr.Unit }}</vs-td>
                                    <vs-td>{{ tr.Amount }}</vs-td>
                                    <vs-td>{{ tr.StorageCode }}</vs-td>
                                    <vs-td>{{ tr.Batch }}</vs-td>
                                    <vs-td>{{ formatDate(tr.ExpiredDate) }}</vs-td>
                                    <vs-td>{{ indextr >= t.StockRelocationPickingItemStocks.length || t.StockRelocationPickingItemStocks.length == 0 ? '' : t.StockRelocationPickingItemStocks[indextr].StorageCode }}</vs-td>
                                    <vs-td>{{ indextr >= t.StockRelocationPickingItemStocks.length || t.StockRelocationPickingItemStocks.length == 0 ? '' : t.StockRelocationPickingItemStocks[indextr].Batch }}</vs-td>
                                    <vs-td>{{ indextr >= t.StockRelocationPickingItemStocks.length || t.StockRelocationPickingItemStocks.length == 0 ? '' : formatDate(t.StockRelocationPickingSuggestions[indextr].ExpiredDate) }}</vs-td>
                                </vs-tr>
                            </template>
                        </template>
                    </template>
                </vs-table>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";

export default {
    components: {
    },
    data() {
        return {
            pickingDate: this.date,
            id: null,
            readonly: true,
            code: null,
            status: null,
            pickingId: 0,
            pickingCode: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            destAddress: null,
            reqDeliveryDate: null,
            eta: null,
            notes: null,
            file: null,
            fileUrl: '',
            pickingItem: [],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.loadPage()
        }
    },
    methods: {
        async loadPage() {
            await this.getData()
            await this.getSuggest()
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get("/api/wms/v1/outbound/sto-planner/" + this.id);
            if (resp.code == 200) {
                this.code = resp.data.code
                this.status = resp.data.status
                this.pickingId = resp.data.picking_id
                this.pickingCode = resp.data.picking_code
                this.selectedSrcWarehouse = resp.data.selected_src_warehouse.label
                this.selectedDestWarehouse = resp.data.selected_dest_warehouse.label
                this.destAddress = resp.data.selected_dest_warehouse.name
                this.reqDeliveryDate = moment(resp.data.req_delivery_date).toDate()
                this.eta = moment(resp.data.eta).toDate()
                this.notes = resp.data.notes
                this.fileUrl = resp.data.file_url
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
        async getSuggest() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/outbound/sto-planner/get-suggestion", {
                    params: {
                        picking_id: this.pickingId,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.pickingItem = resp.data.StockRelocationPickingItems
                    }
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleBack() {
            this.$router.push({
                name: "outbound.stock-transfer-order-planner",
                params: {status: 2}
            });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>